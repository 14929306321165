import { defineStore } from 'pinia'
import {
	getCategoryTree,
	getCategories,
	searchArticles,
	Category,
	CategoryTree,
	Article,
} from 'ic-js-content'
import { NewsPageArticles, AppliedFilter } from '../assets/types/util'

export const useContentStore = defineStore('content', () => {
	let storeReady = ref(false)
	let categories = ref<Category[]>([])
	let categoryTree = ref<CategoryTree[]>([])

	const initialPageSize = ref(14)
	const loadMorePageSize = ref(5)
	let newsPageArticles = ref<NewsPageArticles>({})
	let additionalArticlesToFetch = ref(false)
	let additionalArticleGroups = ref(0)
	let initialLoadDone = ref(false)
	let isFetchingAdditionalArticles = ref(false)
	let categoryFilterQuery = ref('')
	let selectedFilters = ref([])
	const globalVideoArticle = ref<Article|null>(null)
	const globalVideoArticleModalReturnPath = ref('')
	const route = useRoute()

	const initContentStore = async () => {
		if (storeReady.value) return
		await setCategories()
		await setCategoryTree()
		storeReady.value = true
	}

	const setCategories = async () => {
		const { data } = await getCategories()
		categories.value = data
	}

	const setCategoryTree = async () => {
		const { data } = await getCategoryTree()
		categoryTree.value = data
	}

	const setCategoryFilter = (appliedFilters: AppliedFilter[]) => {
		// everytime the filters get set, we empty out the current articles because they need to be refetched with the new filters
		newsPageArticles.value = {}

		categoryFilterQuery.value = ''
		selectedFilters.value = appliedFilters.map(af => af.value).flat()

		if (selectedFilters.value) {
			categoryFilterQuery.value = ' AND "'
			categoryFilterQuery.value += selectedFilters.value.join('" AND "')
			categoryFilterQuery.value += '"'
		}
	}

	const resetState = () => {
		initialLoadDone.value = false
		additionalArticleGroups.value = 0
		additionalArticlesToFetch.value = false
	}

	const resetSelectedFilters = () => {
		selectedFilters.value = []
	}

	const loadInitialArticles = async (slug: string) => {
		if (!newsPageArticles.value[slug]?.length) {
			resetState()
		}

		const categoryIdFromSlug = categories.value.find((category: Category) => category.slug === slug)?.id

		const params = {
			size: initialPageSize.value,
			page: 0,
			query: `singlePage:false AND categories.id:("${categoryIdFromSlug}"${categoryFilterQuery.value})`,
		}

		if (!categoryIdFromSlug) {
			initialLoadDone.value = true
			return
		}

		const { data: articlesData, metadata: articleMetadata } = await searchArticles({ params })

		const { pageNumber, totalPages } = articleMetadata

		newsPageArticles.value = {
			...newsPageArticles.value,
			[slug]: {
				articles: [...articlesData.articles],
				pageNumber: 1,
				totalPages,
			},
		}

		initialLoadDone.value = true
		newsPageArticles.value[slug].additionalArticlesToFetch = +pageNumber + 1 < totalPages
		newsPageArticles.value[slug].additionalArticleGroups = 0
	}

	const loadMoreArticles = async (slug: string) => {
		const categoryIdFromSlug = categories.value.find((category: Category) => category.slug === slug)?.id
		isFetchingAdditionalArticles.value = true

		if (!categoryIdFromSlug) {
			return
		}

		// GET FIRST 4 ARTICLE IDS
		// EXCLUDE ARTICLES WITH THOSE IDS IN THE serArticleByQuery
		const excludedArticleIds = newsPageArticles.value[slug].articles.slice(0, 4).map(article => article.id)
		const excludedString = `"${excludedArticleIds.join('" OR "')}"`

		const params = {
			size: loadMorePageSize.value,
			page: newsPageArticles.value[slug].pageNumber + 1,
			query: `singlePage:false AND categories.id:("${categoryIdFromSlug}"${categoryFilterQuery.value}) AND NOT id:(${excludedString})`,
		}

		// filter out the articles by the selected categories
		// additionaly, filter out the first 2 articles so that we don't get repeating articles
		const { data: articlesData, metadata: articlesMetadata } = await searchArticles({ params })

		const { pageNumber, totalPages } = articlesMetadata

		// show/hide load-more button; pageNumber is zero-based
		additionalArticlesToFetch.value = +pageNumber + 1 < totalPages

		additionalArticleGroups.value++
		isFetchingAdditionalArticles.value = false

		newsPageArticles.value = {
			...newsPageArticles.value,
			[slug]: {
				articles: [...newsPageArticles.value[slug].articles, ...articlesData.articles],
				pageNumber,
				totalPages,
				additionalArticlesToFetch: additionalArticlesToFetch.value,
				additionalArticleGroups: additionalArticleGroups.value,
				isFetchingAdditionalArticles: isFetchingAdditionalArticles.value,
			},
		}
	}

	const setGlobalVideoArticle = (payload: Article | null) => {
		// switch of articles via modalArticleComponent without close
		if (!globalVideoArticle.value?.id && payload?.id) globalVideoArticleModalReturnPath.value = route.path
		globalVideoArticle.value = payload
	}

	return {
		categories,
		categoryTree,
		initContentStore,
		newsPageArticles,
		initialLoadDone,
		loadInitialArticles,
		loadMoreArticles,
		setCategoryFilter,
		selectedFilters,
		resetSelectedFilters,
		setGlobalVideoArticle,
		globalVideoArticle,
		globalVideoArticleModalReturnPath,
	}
})
